/* eslint-disable */
<template>
    <div>Processing login...</div>
</template>

<script>
import { authProvider, setActiveAccount } from "../../plugins/auth";

export default {
    name: "Callback",
    mounted() {
        this.handleRedirect();
    },
    methods: {
        async handleRedirect() {
            try {
                const response = await authProvider.handleRedirectPromise(); // Correct method
                if (response) {
                    console.log("Redirect login successful:", response);
                    setActiveAccount(); // Set the active account after login
                    this.$router.push("/"); // Redirect to the home page
                } else {
                    console.log("No redirect response received.");
                }
            } catch (error) {
                console.error("Error handling redirect:", error);
            }
        },
    },
};
</script>

<style>
</style>