/* eslint-disable */
<template>
</template>

<script>
import { login } from "../../plugins/auth"; // Import the login function
import router from "@/router"; // Import the router instance

export default {
    mounted() {
        this.handleLogin();
    },
    methods: {
        async handleLogin() {
            try {
                await login(); // Call the login function
                console.log("Login successful");

                // Redirect the user to the home page
                router.push({ name: "home" });
            } catch (error) {
                console.error("Login failed:", error);
            }
        },
    },
};
</script>

<style>
</style>