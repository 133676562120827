import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580",
        redirectUri: "/auth/callback", // Ensure this matches your app's redirect URI
        postLogoutRedirectUri: "/", // Redirect here after logout
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage", // Store tokens in sessionStorage
        storeAuthStateInCookie: false, // Set to true if you experience issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            logLevel: "Info", // Set log level (e.g., "Error", "Warning", "Info", "Verbose")
        },
    },
};

export const authProvider = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", "user.read", "email"],
};

// Login method using redirect
export const login = async () => {
    try {
        // Check if an interaction is already in progress
        const accounts = authProvider.getAllAccounts();
        if (accounts.length > 0) {
            console.log("User is already logged in.");
            setActiveAccount(); // Set the active account if not already set
            return;
        }

        // Proceed with login if no interaction is in progress
        await authProvider.loginRedirect(loginRequest);
    } catch (error) {
        if (error.errorCode === "interaction_in_progress") {
            console.warn("Login interaction is already in progress.");
        } else {
            console.error("Login failed:", error);
            throw error;
        }
    }
};

// Logout method
export const logout = () => {
    // Clear the session storage
    sessionStorage.clear();
    console.log("Session cleared. Logging out...");

    // Call logoutRedirect to end the session and redirect after logout
    authProvider.logoutRedirect({
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri, // Redirects after logout
    });
};

// Set the active account after login
export const setActiveAccount = () => {
    const accounts = authProvider.getAllAccounts();
    if (accounts.length > 0) {
        authProvider.setActiveAccount(accounts[0]); // Set the first account as the active account
        console.log("Active account set.", accounts[0]);
    }
};

// Get the active account
export const getActiveAccount = () => {
    const activeAccount = authProvider.getActiveAccount();
    console.log("Active account:", activeAccount);
    return activeAccount;
};