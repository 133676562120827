<template>
  <div>
    <h5>You have been signed out. Please close your browser window and reopen the application.</h5>
  </div>
</template>

<script>
import { logout } from "../../plugins/auth";

export default {
  name: "Logout",
  mounted() {
    // Call the exported logout method which uses logoutRedirect
    logout();
  }
};
</script>

<style>
/* ...style rules... */
</style>