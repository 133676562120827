/* eslint-disable */

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/auth/login.vue";
import Callback from "../views/auth/callback.vue";
import loader from "../mixins/loader";
import store from "@/store/index.js";
import axios from "axios";
import CreateOrg from "@/views/supervisoryorg/CreateOrg.vue";
import ChangeOrg from "@/views/supervisoryorg/ChangeOrg.vue";
import DeleteOrg from "@/views/supervisoryorg/DeleteOrg.vue";
import Logout from "../views/auth/logout.vue";
import displayError from "../mixins/errorHandler";

import { authProvider, loginRequest, setActiveAccount, getActiveAccount } from "@/plugins/auth"; // Import loginRequest here

var jwt = require("jsonwebtoken");

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/auth/callback",
    name: "callback",
    component: Callback,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/create/:person",
    name: "create",
    component: CreateOrg,
  },
  {
    path: "/change/:person",
    name: "change",
    component: ChangeOrg,
  },
  {
    path: "/delete/:person",
    name: "delete",
    component: DeleteOrg,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async function (to, from, next) {
  // Optionally exclude routes such as login/callback from triggering token refresh
  if (to.name === "Login" || to.name === "callback") {
    next();
    return;
  }

  // Always call the centralized token retrieval/check function
  await getAccessTokenOpenID();
  next();
});

// Function to get Access Token from the Auth Provider (AD) and get back user data
async function getAccessTokenOpenID() {
  console.log("Getting access token...");
  try {
      // Ensure the active account is set
      console.log("Checking active account...");
      if (!getActiveAccount()) {
          console.log("No active account found. Setting active account...");
          setActiveAccount();
      }

      const activeAccount = getActiveAccount();
      console.log("Active account:", activeAccount);

      if (!activeAccount) {
          console.warn("No active account found. Redirecting to login...");
          await authProvider.loginRedirect(loginRequest);
          return;
      }

      // Attempt to acquire token silently
      console.log("Attempting to acquire token silently...");
      const tokenResponse = await authProvider.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount,
      });
      console.log("Token response:", tokenResponse);

      // Use msal-browser's idTokenClaims to build the user data
      const claims = tokenResponse.idTokenClaims;
      const userData = {
          // Use 'name' or fallback to preferred_username (or any other claim)
          name: claims.name || claims.preferred_username,
          uid: activeAccount.localAccountId,
          email: activeAccount.username,
          token: tokenResponse.accessToken,
          // Optionally store additional claims if needed
          idTokenClaims: claims,
      };

      if (!userData.uid || !userData.token) {
          console.error("UID or token is missing in userData:", userData);
          return;
      }

      // Store user data in sessionStorage and update the store
      sessionStorage.setItem("userData", JSON.stringify(userData));
      sessionStorage.setItem("isLoggedOut", false);
      store.dispatch("addUserData", userData);

      // If needed, perform any further user checks here
  } catch (error) {
      console.error("Error acquiring token silently:", error);
      try {
          await authProvider.loginRedirect(loginRequest);
      } catch (loginError) {
          if (loginError.errorCode === "interaction_in_progress") {
              console.warn("Interactive login is already in progress.");
          } else {
              console.error("Login failed:", loginError);
          }
      }
  }
}

// Check if user is a supervisor or not and get supervisory structure
async function checkUser(token, uid) {
  console.log("Token:", token); // Debugging statement
  console.log("UID:", uid); // Debugging statement
  console.log("Host Name:", store.getters.getHostName); // Debugging statement

  if (!token || !uid) {
      console.error("Token or UID is missing.");
      return;
  }

  try {
      const decodedToken = jwt.decode(token);
      console.log("Decoded Token (checkUser):", decodedToken); // <---- Add this line
  } catch (decodeError) {
      console.error("Error decoding token:", decodeError);
      return;
  }

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const dateString = `${year}-${month}-${day}`;
  const apiUrl = `${store.getters.getHostName}/supervisoryorgs/${uid}/${dateString}`;
  console.log("API URL:", apiUrl); // Debugging statement

  try {
      const response = await axios.get(apiUrl, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });

      if (response.status === 200) {
          console.log("User data retrieved successfully:", response.data);
      } else {
          console.error("Failed to retrieve user data:", response);
      }
  } catch (error) {
      console.error("Error in checkUser API call:", error);
  }
}

// Function to take the API result and transform into something the Org Chart API can use
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].Org_Code] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (i == list.length - 1) {
      node["last_node_class"] = "chart-row-lastchild";
    }
    if (i !== 0) {
      list[map[node.Superior_Code]]["children"].push(node);
    }
    if (list.length == 2 && i == 0) {
      node["last_node_class"] = "chart-row-lastchild";
      roots.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

// Actual Get Supervisor Org API Call with Axios
async function httpGetuserAccess(url, silent, token) {
  return axios({
    method: "get",
    maxBodyLength: Infinity,
    url: `${store.getters.getHostName}${url}`,
    responseType: "json",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.data.status == 200) {
        return response.data;
      } else if (response.data.status == 2000) {
        displayError(response.data.msg);
      } else if (response.data.status == 2001) {
        return response.data;
      } else {
        displayError(response.data.msg);
      }
    })
    .catch((error) => {
      loader.hideLoading(silent == true ? silent : false);
      displayError(error);
    });
}

export default router;